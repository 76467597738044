import React from "react";
import LoadingView from "./LoadingView";
import AsyncComponent from "./AsyncComponent";

const App: React.FC = () => {
	return (
		<AsyncComponent
			loader={() => <LoadingView />}
			importer={() => import("./Resume")}
		>
			{Resume => <Resume />}
		</AsyncComponent>
	);
}

export default App;
