import React from "react";

interface LoadingViewProps {
	// todo
}

const LoadingView = (props: LoadingViewProps) => {
	return (
		<div
			style={{
				position: "absolute",
				top: "30%",
				left: 0,
				right: 0,
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				opacity: 0.3,
			}}
		>
		</div>
	);
};

export default LoadingView;