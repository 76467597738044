import React from "react";
import { useState, useEffect } from "react";

interface AsyncComponentProps {
	loader: () => any;
	importer: () => Promise<any>;
	children: (value: any) => React.ReactNode;
}

const AsyncComponent: React.FC<AsyncComponentProps> = (props) => {
	const {
		loader,
		importer,
		children,
	} = props;

	const [component, setComponent] = useState<any>(undefined);

	useEffect(() => {
		importer().then(res => {
			const c = res.default;
			setComponent({ value: c });
		}).catch(e => {
			console.error("async component load failed");
			throw e;
		});
	}, [importer]);

	if (component !== undefined) {
		const child = children(component.value);
		return child;
	}

	return loader();
};

export default AsyncComponent;